.vendorList {
  width: 100%;
  height: 100%;
  background-color: #3d4832c2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // padding: 5px;
  .grid {
    display: flex;
    margin: 10px;
    background-color: white;
    height: 100%;
    width: calc(100% - 10px);
    border-radius: 10px;
    // outline: 10px;
    // border-inline: 5px solid red;

    .datagrid {
      height: 100%;
      width: 100px;
      // outline: 0px;
      border-width: 0px;
      // padding: 5px;
      .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
        color: #3d4832;
      }
    }
  }
  .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    color: #3d4832;
  }
  .exportButton {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-radius: 10px;
    justify-content: flex-end;
    margin: 5px;
    margin-right: 15px;
    background-color: #ffffff;
  }
}
