.title{

    background-color: gray;
    display: flex;
    justify-items: center;
    align-items: center;
}



.contentDialog{
     
    overflow-x: hidden !important;
   
}
