.forgot-password {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  .leftbox {
    position: relative;
    width: 50%;
    background-color: #3d4832;
    height: 100%;
    align-items: center;
    align-content: center;
    text-align: center;
    z-index: 2;

    // @media (max-width: 1500px) {
    //   width: 40%;
    // }

    .image {
      top: 30%;
      position: relative;
      height: 20rem;
      width: 20rem;
      z-index: 2;
      border-radius: 160px;
    }
  }

  .forgotPasswordBox {
    display: flex;
    align-items: flex-end;
    width: 50%;
    height: 100%;
    // background-color: rgb(239, 239, 252);

    .forgotPasswordInnerBox {
      position: relative;
      padding: 10rem;
      display: flex;
    }
  }

  //   .leftbox::after {
  //     content: "";
  //     z-index: 1;
  //     position: absolute;
  //     left: 80%;
  //     bottom: 0;
  //     top: 0;
  //     width: 40%;
  //     background-color: #3d4832;
  //     transform: skewX(-15deg);

  // @media (max-width: 1500px) {
  //   width: 50%;
  //   left: 70%;
  //   transform: skewX(-15deg);
  // }
  //   }
}

@media (max-width: 1500px) {
  .leftbox {
    width: 30%;
  }
  .forgotPasswordBox {
    width: 60%;
    position: relative;
    // left: 0%;
    height: 70%;
    width: 100%;
    text-align: center;
    .forgotPasswordInnerBox {
      padding: 3rem;
      width: 70%;
    }
  }
}
@media (max-width: 1300px) {
  .leftbox {
    display: none;

    .image {
      display: none;
    }
  }
  .forgotPasswordBox {
    width: 60% !important; 
    position: relative !important;
    // left: 0%;
    height: 70% !important;
    width: 100% !important;
    text-align: center !important;
    .forgotPasswordInnerBox {
      padding: 3rem !important;
      width: 100% !important;
    }
  }
}
@media (max-width: 1030px) {
  .login {
    .leftbox {
      width: 100%;
      // background-color: #3D4832;
      height: 100%;
      align-items: center;
      // align-content: center;
      text-align: center;
      // justify-content: center;
      // z-index: 2;
      .image {
        top: 30%;
        position: relative;
        height: 20rem;
        width: 20rem;
        z-index: 2;
        border-radius: 160px;
      }
    }
    flex-direction: column;
    .loginBox {
      // display: flex;
      // flex: 1;
      //    align-items: center;
      width: 100%;
      height: 100%;
      .loginInnerBox {
        padding: 0rem;
        align-items: center;
        justify-content: center;

        width: 100%;
      }
    }
  }
}
