.ps-active {
  background-color: rgb(140, 141, 141) !important;
  border-radius: 15px;
}

.ps-menu-label {
  overflow: visible !important;
}
.css-1wvake5 {
  width: 270px !important;
}
.css-1wvake5.ps-collapsed {
  width: 55px !important;
  min-width: 55px !important;
}

.ps-menu-button {
  // align-items: end !important;
  justify-content: flex-start;
}
.css-1m3bo6 {
  justify-content: end !important;
}
.css-ewdv3l {
  margin-left: -6px !important;
}
