.login {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // align-content: center;
  text-align: center;
  .leftbox {
    flex: 1;
    position: relative;
    width: 50%;
    // background-color: #3D4832;
    height: 100%;
    align-items: center;
    // align-content: center;
    text-align: center;
    // justify-content: center;
    z-index: 2;
    .image {
      top: 30%;
      position: relative;
      height: 20rem;
      width: 20rem;
      z-index: 2;
      border-radius: 160px;
    }
  }

  .loginBox {
    // display: flex;
    flex: 1;
    align-items: flex-end;
    width: 50%;
    height: 100%;
    // background-color: rgb(239, 239, 252);
    .loginInnerBox {
      position: relative;
      padding: 10rem;
      display: flex;
      .button {
        position: relative;
        background-color: green;
        width: 70%;
        height: 40px;
        font-size: 15px;
      }
    }
  }

  @media (max-width: 1500px) {
    .leftbox {
      width: 30%;
    }
    .loginBox {
      width: 60%;
      position: relative;
      // left: 0%;
      height: 70%;
      width: 100%;
      text-align: center;
      .loginInnerBox {
        padding: 3rem;
        width: 70%;
      }
    }
  }
}
@media (max-width: 1030px) {
  .login {
    .leftbox {
      width: 100%;
      // background-color: #3D4832;
      height: 100%;
      align-items: center;
      // align-content: center;
      text-align: center;
      // justify-content: center;
      // z-index: 2;
      .image {
        top: 30%;
        position: relative;
        height: 20rem;
        width: 20rem;
        z-index: 2;
        border-radius: 160px;
      }
    }
    flex-direction: column;
    .loginBox {
      // display: flex;
      // flex: 1;
      //    align-items: center;
      width: 100%;
      height: 100%;
      .loginInnerBox {
        padding: 0rem;
        align-items: center;
        justify-content: center;

        width: 100%;
      }
    }
  }
}
//     .loginInnerBox{

//         padding: 5rem !important;

//     }
//     .leftbox{
//     // display: none;

//        }
//         .login{
//         // flex-direction: ;
//         }
//         .leftbox::after{
//             display: none;
//         }

//     }
//     @media (max-width: 836px) {

//         .loginInnerBox{

//             padding: 5rem !important;

//         }
//         .leftbox{
//         // display: none;
//             height: 30% !important;
//             width: 100% !important;
//             // align-items: center;
//             .image{
//                 top: 10% !important;
//                position: relative;
//             //    align-self: center;
//                height: 10rem !important;
//                width: 10rem !important;
//                z-index: 2;
//                border-radius: 160px;
//            }
//     }
//             .login{
//              flex-direction:column ;
//             //  align-content: center;
//              .loginBox{
//                 position: relative;
//                 // left: 0%;
//                 height: 70%;
//                 width: 100%;
//                 text-align: center;
//                 .loginInnerBox{
//                     position: relative;
//                     // left: 15%;

//                 }
//              }
//             }
//             .leftbox::after{
//                 display: none;
//             }

//         }
// @media (max-width: 500px) {

//             .loginInnerBox{

//                 padding: 5rem !important;

//             }
//             .leftbox{
//             // display: none;
//                 height: 30% !important;
//                 width: 100% !important;
//                 // align-items: center;
//                 .image{
//                     top: 10% !important;
//                    position: relative;
//                 //    align-self: center;
//                    height: 10rem !important;
//                    width: 10rem !important;
//                    z-index: 2;
//                    border-radius: 160px;
//                }
//         }
//                 .login{
//                  flex-direction:column ;
//                 //  align-content: center;
//                  .loginBox{
//                     position: relative;
//                     align-items: flex-start;

//                     // left: 0%;
//                     height: 70%;
//                     width: 100%;
//                     text-align: center;
//                     .loginInnerBox{
//                         position: relative;
//                         // left: 15%;
//                         padding-left : 5px !important;
//                         padding-right : 5px !important;

//                     }
//                  }
//                 }
//                 .leftbox::after{
//                     display: none;
//                 }
