.adminUserMan{

    background-color: #6b6868;
    width: 100%;
    height: 100%;

    .addUser{
        width: 100%;
        height: 20%;
        display: flex;
         flex-direction: column;
        align-items: center;
        position: relative;
    background-color: #3D4832;
     .userMessageDisplay{
         background-color:#c9c8c8 ;
         display: flex;
         flex-direction: row;
                        }
        .addUser1{
            width: auto;
            padding: 15px;
            padding-top: 20px;
            height: auto;
            margin: auto;
            background-color: #c9c8c8;
            border-radius: 5px;

            .userInput{
                margin: 5px;
                height: 3.4rem;
                border-color:#3D4832 ;
                color: #3D4832;
                
                .css-1d6wzja-MuiButton-startIcon>*:nth-of-type(1){
                    color: #3D4832;
                    }
                #text {
                    color: #3D4832;

                    }
            }
        }
    }
    .checkUsers{
        background-color: #3D4832;
        width: 100%;
        height: 80%;
        display: flex;
        position: relative;
        flex-direction: column;
        .db{
            background-color: #c9c8c8;
             margin: 0px 1rem;
            width: calc(100% - 3rem);
            border-radius: 5px; 
            height: 80%;
            padding: 10px;
        }
        .deleteButton{
            padding: 10px;
            .css-180lf2n-MuiButtonBase-root-MuiButton-root{
                background-color: #c9c8c8;

            }
        }
        
    }
}

#outlined-helperText{
    padding:16.5px 14px !important ;
}