.app {
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: row;
 
      text-align: center;
      margin: 0px;
      padding: 0px;
    
  }
  .content{
    width: 100%;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgb(141, 173, 241);
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-track:hover {
    background: #555;
  }
  .sidebar{
  position: relative;
  }