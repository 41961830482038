.viewContentInner{
padding-left: 10px;


.listLearner{
    width: 95%;
    background-color:whitesmoke;
    .folder{
        margin-left: 30px;
        
    }
    .files{
        margin-left: 60px;
    }
}
}
.css-sghohy-MuiButtonBase-root-MuiButton-root{
    background-color: #3D4832 !important;
}