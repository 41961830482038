.navbarBox{
    display:flex ;
    justify-content: space-between;
    padding : 0px !important;
    background-color: #3D4832;
    width: 100%;

    .logo {
        border-radius: 3px;
        display:flex ;
        padding-left: 10px;
        justify-content: center;
        align-items: center;
        img {
            width:60px;
            height: 60px;
            cursor: pointer;
        }
        .h6{
            padding: 5px;
            color: rgb(255, 255, 255);
            margin-left: 10px;
        }
    }
    .rightBox{
        display:flex ;
        align-items: center;
        width: 8rem;

        button {
            
            padding: 10px;
            width: 7rem;
            height: 1.8rem;
            margin: 5px;
            .innerText{
            width: 10rem;
            color: aliceblue;

            }
            .innerButton{
            color: aliceblue;

            }
        }
    }
}
