.mainDiv {
  //background-color: grey;
  width: 100%;
  height: 100vh; // changed from 100% to 100vh for full height
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logoBox {
    //background-color: blueviolet;
    width: 20%;
    // height: 30%;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    .circular-image {
      width: 100%;
      //   height: 80%;
      border-radius: 50%;
      object-fit: cover;
      //border: 10px solid green;
    }
  }
  .boxSetup {
    // background-color: red;
    width: 70%;
    height: 40%;
    border-radius: 20px;
    justify-content: center;
    // align-items: center;
    display: flex;
    flex-direction: row;

    .buttonBoxOuter {
      flex: 1;
      //   height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      .buttonbox {
        flex: 1;
        width: 100%;
        // height: 40px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
          position: relative;
          background-color: green;
          width: 80%;
          height: 100px;
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .logoBox {
    width: 30% !important;
    margin-bottom: 40px !important;
  }
  .boxSetup {
    width: 90% !important;
    .buttonBoxOuter {
      .buttonbox {
        width: 100%;

        .button {
          //   width: 80%;
          //   height: 100px;
          font-size: 15px !important;
        }
      }
    }
  }

  //   }

  //   .boxC {
  //     flex: 1;
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     .buttonC {
  //       position: relative;
  //       background-color: green;
  //       width: 80%;
  //       height: 60%;
  //       font-size: 40px;
  //     }
  //   }
}

// .boxD {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   display: flex;
//   height: 100%;
//   justify-content: center;
//   align-items: center;

//   .boxE {
//     flex: 1;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .buttonE {
//       position: relative;
//       background-color: green;
//       width: 80%;
//       height: 60%;
//       font-size: 40px;
//     }
//   }

// //   .boxF {
// //     flex: 1;
// //     width: 100%;
// //     display: flex;
// //     justify-content: center;
// //     align-items: center;

// //     .buttonF {
// //       position: relative;
// //       background-color: green;
// //       width: 80%;
// //       height: 60%;
// //       font-size: 40px;
// //     }
// //   }
// }
