.companyDetailsButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  .companyDetailsButtonInner {
    display: flex;
    flex-direction: row;
  }
}
.textfield{
  width: 60% !important;
  margin: 0px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 5px 10px !important;
}
.css-11qr2p8-MuiButtonBase-root-MuiButton-root{
  background-color: #3D4832 !important;
}
// 