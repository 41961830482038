.calendar-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modal-container {
  position: absolute !important;
  width: 500px !important;
  background-color: #fff !important;
  border-radius: 8px !important;
  padding: 32px !important;
  max-height: 90vh !important;
  overflow-y: auto !important;
}

.modal-title {
  margin-bottom: 24px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.form-field {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.form-field .MuiOutlinedInput-root {
  height: 48px !important;
}

.form-field .MuiInputBase-input {
  height: 48px !important;
  padding: 0 14px !important;
}

.time-picker {
  width: 100% !important;
}

.email-input-container {
  margin-bottom: 20px !important;
}

.email-input {
  margin-bottom: 10px !important;
}

.email-chips-container {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
}

.email-chip {
  height: 32px !important;
  background-color: #e0e0e0 !important;
}

.email-chip .MuiChip-label {
  color: #333 !important;
}

.select-field .MuiSelect-select {
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
}

.comment-field .MuiOutlinedInput-root {
  height: auto !important;
  min-height: 120px !important;
}

.comment-field .MuiInputBase-input {
  height: auto !important;
  padding: 14px !important;
}

.button-container {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 16px !important;
  margin-top: 20px !important;
}

.action-button {
  height: 48px !important;
  min-width: 100px !important;
}

.email-loading {
  margin-right: 12px !important;
  color: #1976d2 !important;
}

.email-input .Mui-disabled {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.add-email-button {
  padding: 8px !important;
}

.add-email-button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.error-message {
  color: #d32f2f !important;
  margin-bottom: 16px !important;
  font-size: 0.75rem !important;
}

.email-input .MuiFormHelperText-root {
  color: #d32f2f !important;
  margin-left: 14px !important;
}

.email-input .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.form-field .MuiFormHelperText-root {
  color: #d32f2f !important;
  margin-left: 14px !important;
}

.form-field.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.form-field .MuiInputLabel-asterisk {
  color: #d32f2f !important;
}
