.vendorCreate {
  width: 95%;
  // height: 100%;
  //   background-color: red;
  // position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
  align-items: center;
 
  .CreateBox {
    // background-color: gray;
    width: 80%;
    height: 60%;
    border-radius: 20px;
    flex-direction: column;
    gap: 20px;
    display: flex;
  }

  .vendorlable {

    width: 20%;
    // height: 20%;
    font-size: large;
    padding: 5px;
    // background-color: blueviolet;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    
   }

  .SubmitBox {
    position: relative;
    display: flex;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    margin: 5px;
    // height: 20%;
    margin-right: 15px;
    .SubmitButton {
    }
  }
}
